import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7fae0f68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SysTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "机构",
          prop: "corpId"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_cascader, {
              ref: el=>_ctx.refMap.set('corpId',el),
              modelValue: _ctx.form.corpId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.corpId) = $event)),
              placeholder: "请选择机构",
              style: {"width":"100%"},
              options: _ctx.cascaderData({comboId:'corpId'}),
              disabled: _ctx.disabled,
              onChange: _cache[1] || (_cache[1] = (val)=>{_ctx.cascaderOnChange(val,'corpId')})
            }, null, 8, ["modelValue", "options", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "系统标题",
          prop: "title"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.title,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.title) = $event)),
              placeholder: "请输入系统标题",
              maxlength: "25",
              disabled: _ctx.disabled,
              clearable: ""
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "说明",
          prop: "desc"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "textarea",
              modelValue: _ctx.form.desc,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.desc) = $event)),
              maxlength: "100",
              placeholder: "请输入说明",
              clearable: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}